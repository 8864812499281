<template>
  <div class="exam-progress-page">
    <div class="row">
      <div class="col-md-12">
        <div class="progress-section">

          <h4>{{ candidate_job.job.title }}</h4>
          <div class="progress-bars">
            <span class="active" @click="active_tab = {}">pending</span>
            <span v-for="assessment in candidate_job.candidate_assessment"
              :class="assessment.exam_end_at ? 'active' : ''" @click="activeTab(assessment)">
              {{ assessment.assessment.type }}
              <!--                            {{ type_arr.sort((a,b)=>assessment.assessment.type.localeCompare(assessment.assessment.type)) }}-->

            </span>
            <span>Result</span>

          </div>
        </div>
        <!--        progress info -->
        <div class="mt-3 progress-img">
          <div class="candidate-name mb-4">
            <b>Hello!</b> <br>
            {{ $store.state.activeUser.full_name }},
          </div>
          <div class="progress-info">
            <!-- pending-content -->
            <div class="pending-content" v-if="!active_tab.assessment">
              <div class="d-flex align-items-center mb-3">
                <div class="title">Status:</div>
                <div class="title-value main-color">Pending</div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div class="title">Job Title:</div>
                <div class="title-value main-color">{{ candidate_job.job.title }}</div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div class="title">Applied at:</div>
                <div class="title-value main-color">{{ candidate_job.created_at }}</div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div class="title">Expected salary:</div>
                <div class="title-value main-color">{{ candidate_job.expected_salary }}</div>
              </div>
              <div class="d-flex align-items-center mb-3 mt-sm-5 mt-3">
                <div class="title">Note:</div>
                <div class="title-value">
                  <p>
                    We have received your application and your application. You have
                    {{ candidate_job.candidate_assessment.length }} Assessment to go with.
                    Click on the assessment from the top process bar and then click start exam to start assessment.
                  </p>
                </div>
              </div>
            </div>
            <!-- MCQ content -->
            <div class="mcq-content" v-else>
              <div class="d-flex align-items-center mb-3">
                <div class="title">Exam Title:</div>
                <div class="title-value main-color">{{ active_tab.assessment.title }}</div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div class="title">Deadline:</div>
                <div class="title-value main-color" v-if="active_tab.assessment.open_to_start">
                  You can start the exam anytime from now
                </div>
                <div class="title-value main-color" v-else>
                  {{ active_tab.exam_end_at ? active_tab.exam_end_at : 'You need to pass the previous exam' }}
                </div>
              </div>
              <div class="d-flex align-items-center mb-3">
                <div class="title">Exam Time:</div>
                <div class="title-value main-color">{{ active_tab.assessment.duration }} Minutes</div>
              </div>

              <div class="d-flex align-items-center mb-3" v-if="active_tab.assessment.type == 'viva'">
                <div class="title">Exam instruction:</div>
                <div class="title-value">
                  <div class=" title-value main-color" v-html="active_tab.assessment.description"></div>
                </div>
              </div>
              <div class="d-flex align-items-center mb-3" v-if="active_tab.assessment.type == 'viva' && already_booked">
                <div class="title">Viva schedule:</div>
                <div class="title-value main-color">{{ schedule_date }} {{ schedule_start }} - {{ schedule_end }}</div>
              </div>

              <div v-if="active_tab.exam_end_at !== null && active_tab.score > 0"
                class="d-flex align-items-center mb-3">
                <div class="title">Your Score:</div>
                <div class="title-value main-color">{{ active_tab.score }}</div>
              </div>
              <div v-else-if="active_tab.exam_end_at !== null && active_tab.score <= 0"
                class="d-flex align-items-center mb-3">
                <div class="title">Your Score:</div>
                <div class="title-value text-warning">Under Review</div>
              </div>
              <div class="d-flex align-items-center mb-3 mt-sm-5 mt-3">
                <router-link v-if="active_tab.assessment.type != 'viva'"
                  :to="{ name: 'startExam', params: { 'uuid': active_tab.unique_id } }" class="title exam-btn">
                  Go to exam
                </router-link>
                <div v-if="active_tab.assessment.type != 'viva'" class="title-value instruction">
                  <p><i class="fa fa-info-circle"></i> Exam instruction</p>
                  <div class="ins-info">
                    <span class="main-color"><b>Note:</b></span>
                    <div v-html="active_tab.assessment.description"></div>
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center mb-3"
                v-if="active_tab.assessment.type == 'viva' && !already_booked && dataObject.start_date">
                <div class="title">Select Schedule date:</div>
                <date-picker class="w-25" :disabled-date="disabledDay" :default-value="new Date()"
                  v-model="selectedDate" @change="onDateChange" valueType="format"></date-picker>
              </div>

              <div class="d-flex align-items-center mb-3"
                v-if="active_tab.assessment.type == 'viva' && !already_booked && selectedDate">
                <div class="title">Select Schedule Time:</div>
                <select class="form-select w-25" aria-label="Default select example" v-model="selectedTime">
                  <template v-for="time  in times">
                    <option :value="time">{{ time }}</option>
                  </template>
                </select>
              </div>

              <div v-if="active_tab.assessment.type == 'viva' && !already_booked">
                <button v-if="selectedDate && selectedTime" class="title exam-btn mt-3 border-0"
                  @click="saveSchedule">Save</button>
              </div>

            </div>
            <!-- written content -->
          </div>
        </div>
      </div>

      <!-- result-content -->
      <div class="mt-3 result-section d-none">
        <div class="result-content">
          <div class="d-flex flex-column align-items-center mb-3">
            <div class="img">
              <img src="/assets/dashboard/result-badge.svg" alt="">
            </div>
            <div class="h3 main-color">Congratulation !</div>
            <p class="text-center">
              We are glad to inform you that we would love to have you
              in our organization. We have sent you a offer latter on via email, please comfirm us within <b>5
                days</b>.
            </p>
            <a class="offer-letter-btn exam-btn" href="">Download Offer latter</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../../axios";
import login from "@/views/webpages/pages/auth/Login";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: "ExamProgress",
  components: { DatePicker },
  data() {
    return {
      active_tab: {},
      type_arr: ["mcq", "written", "viva"],
      already_booked: false,
      dataObject: {},
      selectedDate: '',
      selectedTime: '',
      exclude_date: [],
      exclude_time: [],
      times: [],
      schedule_start: '',
      schedule_end: '',
      schedule_date: '',
      candidate_job: {
        job: {},
        candidate_assessment: []
      }
    }
  },
  methods: {
    async fetchJob() {
      await axios.get(`/candidate-job/${this.$route.params.uuid}`).then(data => {
        this.candidate_job = data.data
      })
      await this.setCurrentJobState()
    },
    setCurrentJobState() {
      for (let assessment of this.candidate_job.candidate_assessment) {
        if (assessment.exam_end_at !== null) {
          this.active_tab = assessment
        }
      }
    },
    async saveSchedule() {
      let startDate = new Date();
      let [startHours, startMinutes] = this.selectedTime.split(":").map(Number);
      startDate.setHours(startHours, startMinutes, 0, 0);
      let endDate = this.addMinutes(startDate, this.dataObject.duration);
      let data = {
        "job_post": this.dataObject.id,
        "candidate": this.active_tab.candidate_job.candidate,
        "start_time": this.selectedTime,
        "end_time": endDate.toTimeString().substring(0, 5),
        "date": this.selectedDate
      };
      await axios.post(`/create-job-viva-time-slot/`, data)
        .then(res => {
          this.$toast.success('Schedule booked successfully');
          this.loadScheduleData()
        })
        .catch((error) => {
          console.log(error)
          this.$toast.error('Something went wrong!!!');
        });
    },
    async loadScheduleData() {
      await axios.get(`/total-viva-slot-via-job-id/${this.active_tab.candidate_job.job}`)
        .then(res => {
          this.already_booked = res.data.results.already_booked
          if (!this.already_booked) {
            this.dataObject = res.data.results.config_data[0];
            this.exclude_date = res.data.results.list_of_excluded_days;
          } else {
            this.schedule_start = res.data.results.start_time
            this.schedule_end = res.data.results.end_time
            this.schedule_date = res.data.results.date
          }
        })
    },
    disabledDay(date) {
      const startDate = new Date(this.dataObject.start_date);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(this.dataObject.end_date);
      endDate.setHours(0, 0, 0, 0);
      return (date < startDate || date > endDate) || this.checkBookedDate(date);
    },
    checkBookedDate(date) {
      if (this.exclude_date.length > 0) {
        let check = this.exclude_date.find((value, index, array) => {
          return new Date(value).getDate() == new Date(date).getDate();
        })
        return check != undefined ? true : false
      } else {
        return false
      }


    },
    async onDateChange() {
      await axios.get(`/booked-time-slots-via-job-id/${this.active_tab.candidate_job.job}/${this.selectedDate}`)
        .then(res => {
          this.exclude_time = res.data.results.start_time_list ?? []
        })
      this.times = []
      this.generateTimeSlot(this.dataObject.start_time, this.dataObject.end_time, this.dataObject.duration, this.exclude_time)
    },
    generateTimeSlot(start_time, end_time, duration, exclude) {
      let startDate = new Date();
      let [startHours, startMinutes] = start_time.split(":").map(Number);
      startDate.setHours(startHours, startMinutes, 0, 0);

      let endDate = new Date();
      let [endHours, endMinutes] = end_time.split(":").map(Number);
      endDate.setHours(endHours, endMinutes, 0, 0);

      while (startDate < endDate) {
        let time = startDate.toTimeString().substring(0, 5);
        let check = exclude.find((value, index, array) => {
          let [startHours, startMinutes] = value.split(":").map(Number);
          return `${startHours}:${startMinutes}` == time;
        })

        if (check === undefined) {
          this.times.push(time);
        }
        startDate = this.addMinutes(startDate, duration);
      }
    },
    activeTab(candidate_assessment) {
      this.active_tab = candidate_assessment
      if (candidate_assessment.assessment.type == 'viva') {
        this.loadScheduleData()
      } else {
        this.already_booked = false
        this.dataObject = {},
          this.selectedDate = ''
        this.selectedTime = ''
        this.exclude_date = []
        this.exclude_time = []
        this.times = []
        this.schedule_start = ''
        this.schedule_end = ''
        this.schedule_date = ''
      }
    },
    addMinutes(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    }
  },
  async mounted() {
    await this.fetchJob()
    this.candidate_job.candidate_assessment.sort((a, b) => a.assessment.type.localeCompare(b.type_arr))
  }
}
</script>

<style scoped></style>